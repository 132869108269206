import {NumberStyles} from "./NumberStyles";
import CircularNumber from "../CircularNumber/CircularNumber";
import {useEffect, useState} from "react";
import {dataNumber, dataNumber25, dataNumber50, dataNumber75, dataNumber95} from "../../../shared/Utils/DataNumber";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    changeLaunchingBallot,
    endGameForced,
    getSingleBingo, launchBallotNewMessageSocket,
    updateGameInfo
} from "../../../store/ActionsAdmin/BingosActions";
import {LaunchBallotModel} from "../../../domain/models/LaunchBallotModel";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import {openAndCloseModalLaunch_Ballot_Manual} from "../../../store/ActionsAdmin/ModalsActions";
import {GameStorageDataModel} from "../../../domain/models/GameStorageDataModel";
import {getWordGame} from "../../../shared/Utils/GetWordGame";
import {setLocalStorageObj} from "../../../shared/Utils/SetLocalStorageObj";
import {socketBingo} from "../../../../../App";
import {getListBallotToValidateNumberComponent} from "../../../shared/Utils/GetListBallotToValidateNumberComponent";
import {getResponseStringToGameMode} from "../../../shared/Utils/getResponseStringToGameMode";
import {getListBallotPlayedLocalStorage} from "../../../shared/Utils/getListBallotPlayedLocalStorage";
import {resolveLaunchBallotData} from "../../../shared/updateLaunchBallot";
import {resolveGameInfoData} from "../../../shared/updateGameInfoData";
import {alertError} from "../../../shared/Utils/AlertError";

interface INumbersProps {
    listBallotState: GameStorageDataModel | null;
}

export default function Numbers({
                                    listBallotState,
                                }: INumbersProps): JSX.Element {

    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const showModalLaunchBallotManual = useAppSelector((state) => state.modalState.showModalLaunchBallotManual);
    const [first, setFirst] = useState<string>('');
    const [second, setSecond] = useState<string>('');
    const [third, setThird] = useState<string>('');
    const [fourth, setFourth] = useState<string>('');
    const [fifth, setFifth] = useState<string>('');
    const dispatch = useAppDispatch();

    function handleShowNumberInfo(number: string) {
        console.log(number);
        console.log(singleBingo);
        if (singleBingo) {
            const listValidate = getListBallotToValidateNumberComponent(singleBingo.game_mode, singleBingo.session.bingo_limit);
            console.log('lista para validacion');
            console.log(listValidate);
            if (listValidate) {
                if (singleBingo.status === 'inactive') {
                    alertInfo('No se puede lanzar balota, debes iniciar el juego');
                    return;
                }
                if (singleBingo.status === 'end') {
                    alertInfo('No se puede lanzar balota, el juego ha finalizado');
                    return;
                }
                if (singleBingo.status === 'active' && !listValidate.includes(number)) {
                    alertInfo(`${getResponseStringToGameMode(singleBingo.game_mode)}`);
                    return;
                }
                if (singleBingo.status === 'active' && listBallotState && listBallotState.listBallotPlayed.includes(number)) {
                    alertInfo('Esta balota ya fue jugada');
                    return;
                }
                dispatch(openAndCloseModalLaunch_Ballot_Manual(number));
            }
        } else {
            alertError('No hay datos del juego');
        }
    }

    function closeSelectedNumber(dataClose: string) {
        dispatch(openAndCloseModalLaunch_Ballot_Manual(dataClose));
        console.log(dataClose);
    }

    async function handleLaunchBallotManual(value: string) {
        if (singleBingo) {
            dispatch(changeLaunchingBallot(true));
            const dataBallotsPlayed = getListBallotPlayedLocalStorage(`listBallotPlayed_${singleBingo.id}`);
            const ballotPlay = value;
            const word = getWordGame(ballotPlay, singleBingo.session.bingo_limit);
            dispatch(openAndCloseModalLaunch_Ballot_Manual(''));
            const dataLaunch: LaunchBallotModel = {
                'game_id': singleBingo.id.toString(),
                'launch_mode': 'manual',
                'new_ballot': ballotPlay
            }
            dataBallotsPlayed.push(ballotPlay);
            setLocalStorageObj(dataBallotsPlayed, `listBallotPlayed_${singleBingo.id}`);
            const resLaunchBallot = await resolveLaunchBallotData(dataLaunch);
            if (resLaunchBallot === 'finalized') {
                const resGameInfo = await resolveGameInfoData(singleBingo.id);
                dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                dispatch(updateGameInfo(resGameInfo));
                dispatch(endGameForced(singleBingo.id)).then(() => {
                    dispatch(getSingleBingo(singleBingo.id)).then(() => {
                        localStorage.removeItem(`listBallotPlayed_${singleBingo.id}`);
                        dispatch(changeLaunchingBallot(false));
                    });
                });
            } else {
                const resGameInfo = await resolveGameInfoData(singleBingo.id);
                dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                dispatch(updateGameInfo(resGameInfo));
                dispatch(changeLaunchingBallot(false));
            }
        } else {
            console.log('No hay datos del single bingo');
        }
    }

    function ballotSelected(array: any[] | undefined, ballot: string) {
        if (array) {
            return !array.map((ballotInfo) => ballotInfo[1]).find(element => element == ballot);
        }
        return false;
    }

    useEffect(() => {
        if (singleBingo) {
            const bingo_header_UpperCase = singleBingo.bingo_header.toUpperCase();
            setFirst(bingo_header_UpperCase.charAt(0));
            setSecond(bingo_header_UpperCase.charAt(1));
            setThird(bingo_header_UpperCase.charAt(2));
            setFourth(bingo_header_UpperCase.charAt(3));
            setFifth(bingo_header_UpperCase.charAt(4));
        }
    }, []);

    return (
        <NumberStyles>
            <div className="container-header-bingo">
                <span className="header-text-1">{first}</span>
                <span className="header-text-2">{second}</span>
                <span className="header-text-3">{third}</span>
                <span className="header-text-4">{fourth}</span>
                <span className="header-text-5">{fifth}</span>
            </div>

            {singleBingo && singleBingo.session.bingo_limit === 25 &&
                <div className="container-container-numbers-25">
                    {dataNumber25.map((data) => (
                        <div className="container-numbers" key={data.number}>
                            <CircularNumber
                                onClick={() => handleShowNumberInfo(data.number)}
                                position="unset"
                                width={3}
                                height={2.7}
                                background={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                border={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                color={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                font_size={1.3}
                                number_selected={data.number}
                            />
                            {showModalLaunchBallotManual === data.number && ballotSelected(listBallotState ? listBallotState.listBallotPlayed : undefined, data.number) &&
                                <div className="number-info">
                                    <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X
                                    </div>
                                    <div className="info">
                                        <span className="text-bold">Numero de Balota:</span>
                                        <span className="text">{data.number}</span>
                                        <img
                                            onClick={() => handleLaunchBallotManual(data.number.toString())}
                                            className="img-play-launch-ballot"
                                            alt="lanch-ballot"
                                            src="/img/newImage/img-launch-ballot.svg"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }

            {singleBingo && singleBingo.session.bingo_limit === 50 &&
                <div className="container-container-numbers-50">
                    {dataNumber50.map((data) => (
                        <div className="container-numbers" key={data.number}>
                            <CircularNumber
                                onClick={() => handleShowNumberInfo(data.number)}
                                position="unset"
                                width={3}
                                height={2.7}
                                background={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                border={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                color={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                font_size={1.3}
                                number_selected={data.number}
                            />
                            {showModalLaunchBallotManual === data.number && ballotSelected(listBallotState ? listBallotState.listBallotPlayed : undefined, data.number) &&
                                <div className="number-info">
                                    <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X
                                    </div>
                                    <div className="info">
                                        <span className="text-bold">Numero de Balota:</span>
                                        <span className="text">{data.number}</span>

                                        <img
                                            onClick={() => handleLaunchBallotManual(data.number.toString())}
                                            className="img-play-launch-ballot"
                                            alt="lanch-ballot"
                                            src="/img/newImage/img-launch-ballot.svg"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }

            {singleBingo && singleBingo.session.bingo_limit === 75 &&
                <div className="container-container-numbers-75">
                    {dataNumber75.map((data) => (
                        <div className="container-numbers" key={data.number}>
                            <CircularNumber
                                onClick={() => handleShowNumberInfo(data.number)}
                                position="unset"
                                width={3}
                                height={2.7}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                // border={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                // color={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                background={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                border={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                color={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                font_size={1.3}
                                number_selected={data.number}
                            />
                            {showModalLaunchBallotManual === data.number && ballotSelected(listBallotState ? listBallotState.listBallotPlayed : undefined, data.number) &&
                                <div className="number-info">
                                    <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X
                                    </div>
                                    <div className="info">
                                        <span className="text-bold">Numero de Balota:</span>
                                        <span className="text">{data.number}</span>
                                        <img
                                            onClick={() => handleLaunchBallotManual(data.number.toString())}
                                            className="img-play-launch-ballot"
                                            alt="lanch-ballot"
                                            src="/img/newImage/img-launch-ballot.svg"
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }

            {singleBingo && singleBingo.session.bingo_limit === 95 &&
                <div className="container-container-numbers-95">
                    {dataNumber95.map((data) => (
                        <div className="container-numbers" key={data.number}>
                            <CircularNumber
                                onClick={() => handleShowNumberInfo(data.number)}
                                position="unset"
                                width={3.5}
                                height={2.4}
                                background={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                border={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                color={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                font_size={1.3}
                                number_selected={data.number}
                            />
                            {showModalLaunchBallotManual === data.number && ballotSelected(listBallotState ? listBallotState.listBallotPlayed : undefined, data.number) &&
                                <div className="number-info">
                                    <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X
                                    </div>
                                    <div className="info">
                                        <span className="text-bold">Numero de Balota:</span>
                                        <span className="text">{data.number}</span>
                                        <img
                                            onClick={() => handleLaunchBallotManual(data.number.toString())}
                                            className="img-play-launch-ballot"
                                            alt="lanch-ballot"
                                            src="/img/newImage/img-launch-ballot.svg"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }

            {singleBingo && singleBingo.session.bingo_limit === 99 &&
                <div className="container-container-numbers-99">
                    {dataNumber.map((data) => (
                        <div className="container-numbers" key={data.number}>
                            <CircularNumber
                                onClick={() => handleShowNumberInfo(data.number)}
                                position="unset"
                                width={2.6}
                                height={2.3}
                                background={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#FF3A4F" : "#B2B2B2"}
                                border={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? " 3.4817756414413452px solid #ffffff" : "3.4817756414413452px solid #878787"}
                                color={listBallotState && listBallotState.listBallotPlayed.map((ballotInfo: string) => ballotInfo).find((element: any) => element == data.number) ? "#ffffff" : "#878787"}
                                font_size={1.3}
                                number_selected={data.number}
                            />

                            {showModalLaunchBallotManual === data.number && ballotSelected(listBallotState ? listBallotState.listBallotPlayed : undefined, data.number) &&
                                <div className="number-info">
                                    <div onClick={() => closeSelectedNumber('')} className="close-selected-number">X
                                    </div>
                                    <div className="info">
                                        <span className="text-bold">Numero de Balota:</span>
                                        <span className="text">{data.number}</span>
                                        <img
                                            onClick={() => handleLaunchBallotManual(data.number.toString())}
                                            className="img-play-launch-ballot"
                                            alt="lanch-ballot"
                                            src="/img/newImage/img-launch-ballot.svg"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }
        </NumberStyles>
    );
}

