import React from 'react';
import './App.css';
import {Route, Router} from "react-router-dom";
import Home from "./modules/user/presentation/Screen/Home/Home";
import AdminHome from "./modules/admin/presentation/Screens/Home/AdminHome";
import Test from "./modules/admin/presentation/Screens/Test/Test";
import {Provider} from "react-redux";
import {store} from "./common/Redux/Store";
import Login from "./modules/admin/presentation/Screens/Login/Login";
import {createBrowserHistory} from "history";
import IsAuthorizedLogin from "./modules/admin/shared/Hooks/IsAuthorizedLogin";
import IsAuthorizedUser from "./modules/admin/shared/Hooks/IsAuthorizedUser";
import IsAuthorizedAdmin from "./modules/admin/shared/Hooks/IsAuthorizedAdmin";
import ThemeContextProvider from "./modules/admin/shared/Hooks/ThemeContextProvider";
import io from "socket.io-client";

export const socketBingo = io("https://api.labalota.online");
// export const socketBingo = io("http://localhost:4002");

export const history = createBrowserHistory();

function App() {
    return (
        <Provider store={store}>
            <ThemeContextProvider>
                <Router history={history}>
                    <IsAuthorizedLogin>
                        <Route path="/login" children={<Login/>}/>
                    </IsAuthorizedLogin>
                    <IsAuthorizedUser>
                        <Route path="/user" children={<Home/>}/>
                    </IsAuthorizedUser>
                    <IsAuthorizedAdmin>
                        <Route path="/admin" children={<AdminHome/>}/>
                    </IsAuthorizedAdmin>
                    <Route path="/test" children={<Test/>}/>
                </Router>
            </ThemeContextProvider>
        </Provider>
    );
}

export default App;
