import {CurrentGameDataStyles} from "./CurrentGameDataStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {openAndCloseModalCurrentGameDAta} from "../../../store/ActionsAdmin/ModalsActions";
import {handleDateNow} from "../../../shared/Utils/DateNow";
import React from "react";
import html2pdf from "html2pdf.js";


export default function CurrentGameData(): JSX.Element {

    const dispatch = useAppDispatch();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const cardsCountBySessionId = useAppSelector((state) => state.cardsState.cardsCountBySessionId);

    function handleCloseModal() {
        dispatch(openAndCloseModalCurrentGameDAta(false));
    }

    function exportGameReportPDF() {
        console.log('hice click en exportar pdf');
        const showDAte = handleDateNow();
        const refGameReport = document.getElementById('game-report');
        if (refGameReport) {
            const opt = {
                margin: 1,
                filename: `game_report ${singleBingo?.bingo_name} ${showDAte}.pdf`,
                image: {type: 'png', quality: 1},
                html2canvas: {scale: 1},
                jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
            }
            html2pdf().set(opt).from(refGameReport).save();
            handleCloseModal();
        }
    }

    return (
        <CurrentGameDataStyles>
            <div className="container-title">
                <span className="title">Informe juego: {singleBingo?.bingo_name}</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <div key={1} id="game-report" className="content-document">
                <div className="container-name-session">
                    <span className="title">Nombre de la sesión: </span>
                    <span className="data">{singleSessionDatabase?.session_name}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Nombre del juego: </span>
                    <span className="data">{singleBingo?.bingo_name}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Tipo de juego: </span>
                    {singleBingo && singleBingo.game_mode === 'any_row' &&
                        <span className="data">Cualquier fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'all_card' &&
                        <span className="data">Carton Lleno</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'any_col' &&
                        <span className="data">Cualquier Columna</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'row_1' &&
                        <span className="data">Primera Fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'row_2' &&
                        <span className="data">Segunda Fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'row_3' &&
                        <span className="data">Tercera Fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'row_4' &&
                        <span className="data">Cuarta Fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'row_5' &&
                        <span className="data">Quinta Fila</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'col_1' &&
                        <span className="data">Columna B</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'col_2' &&
                        <span className="data">Columna I</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'col_3' &&
                        <span className="data">Columna N</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'col_4' &&
                        <span className="data">Columna G</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'col_5' &&
                        <span className="data">Columna O</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'diagonal' &&
                        <span className="data">Diagonal</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'anti_diagonal' &&
                        <span className="data">Anti diagonal</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'shape_X' &&
                        <span className="data">En cruz</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'shape_L' &&
                        <span className="data">En L</span>
                    }
                    {singleBingo && singleBingo.game_mode === 'corners' &&
                        <span className="data">Cuatro esquinas</span>
                    }
                </div>
                <div className="container-name-session">
                    <span className="title">Número de balotas: </span>
                    <span className="data">{singleSessionDatabase?.bingo_limit}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Balotas jugadas: </span>
                    <span className="data">{gameInfo?.listBallotPlayed.length}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Tarjetones en juego: </span>
                    <span className="data">{cardsCountBySessionId}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Jugadores en juego: </span>
                    <span className="data">{singleSessionDatabase?.users.length}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Ganadores: </span>
                    <span className="data">{gameInfo ? gameInfo.showWinners.length : 0}</span>
                </div>
                <div className="container-name-session">
                    <span className="title">Balotas jugadas: </span>
                </div>
                <div className="container-name-session">
                    {gameInfo && gameInfo.listBallotPlayedData.map((ballot) => (
                        <span className="title">{`${ballot}, `}</span>
                    ))}
                </div>
            </div>
            <span onClick={exportGameReportPDF} className="text-create-user">Exportar</span>
        </CurrentGameDataStyles>
    );
}
