import {ShowGameStyled} from "./ShowGameStyled";
import Numbers from "../Numbers/Numbers";
import LastBallots from "../LastBallots/LastBallots";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import React, {useEffect, useRef, useState} from "react";
import {
    changeIsAutomaticLaunch,
    changeLaunchingBallot,
    changePlayLaunchBallotAuto,
    clearDebugMessage,
    deleteBingo,
    endGameForced,
    getGameInfo,
    getSingleBingo,
    launchBallotNewMessageResponseSocket,
    launchBallotNewMessageSocket,
    resetLaunchingBallot,
    startGameResponseSocket,
    startGameSocket,
    switchDebugMessage,
    updateGameInfo
} from "../../../store/ActionsAdmin/BingosActions";
import {useHistory, useParams} from "react-router-dom";
import {LaunchBallotModel} from "../../../domain/models/LaunchBallotModel";
import SessionInfoBingo from "../SessionInfoBingo/SessionInfoBingo";
import {IParams} from "../ShowSession/ShowSession";
import {changeSessionSelected, getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import Accumulated from "../Accumulated/Accumulated";
import EditGame from "../EditGame/EditGame";
import {
    openAndCloseModalCardsWinnerUserAndGame,
    openAndCloseModalDebugMessage,
    openAndCloseModalEditGame,
    openAndCloseModalLaunch_Ballot_Manual,
    openAndCloseModalUsersSession,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {alertConfirmDelete} from "../../../shared/Utils/AlertConfirmDelete";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {alertError} from "../../../shared/Utils/AlertError";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import CardsWinnerUserAndGame from "../CardsWinnerUserAndGame/CardsWinnerUserAndGame";
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import DraggableDiv from "../DraggableDiv";
import ButtonReloadGame from "../ButtonReloadGame/ButtonReloadGame";
import {socketBingo} from "../../../../../App";
import {listBallotBingoLimit} from "../../../shared/Utils/ListBallotBingoLimit";
import {setLocalStorageObj} from "../../../shared/Utils/SetLocalStorageObj";
import {getBallotGame} from "../../../shared/Utils/GetBallotGame";
import {getWordGame} from "../../../shared/Utils/GetWordGame";
import {removeItemLocalStorage} from "../../../shared/Utils/RemoveItemLocalStorage";
import {getUsersWinnersExport} from "../../../store/ActionsAdmin/UserActions";
import {cleanSingleFinishedGame,} from "../../../store/ActionsAdmin/FinishedGameActions";
import {IoMdCloseCircle} from "react-icons/io";
import {getCountCardsByBingoLimit} from "../../../store/ActionsAdmin/CardActions";
import {resolveLaunchBallotData} from "../../../shared/updateLaunchBallot";
import {getListBallotPlayedLocalStorage} from "../../../shared/Utils/getListBallotPlayedLocalStorage";
import {resolveGameInfoData, updateGameInfoData} from "../../../shared/updateGameInfoData";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";


export default function ShowGame(): JSX.Element {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const id: IParams = useParams();
    const themeActive = useThemeContext();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const launchingBallot = useAppSelector((state) => state.bingoState.launchingBallot);
    const startingGame = useAppSelector((state) => state.bingoState.startingGame);
    const showModalEditGame = useAppSelector((state) => state.modalState.showModalEditGame);
    const countLaunchBallot = useAppSelector((state) => state.bingoState.countLaunchBallot);
    const isLoadingAllCardsWinnerUserAndGame = useAppSelector((state) => state.cardsState.isLoadingAllCardsWinnerUserAndGame);
    const showModalCardsWinnerUserAndGame = useAppSelector((state) => state.modalState.showModalCardsWinnerUserAndGame);
    const singleFinishedGame = useAppSelector((state) => state.finishedGameState.singleFinishedGame);
    const playLaunchBallotAuto = useAppSelector((state) => state.bingoState.playLaunchBallotAuto);
    const isAutomaticLaunch = useAppSelector((state) => state.bingoState.isAutomaticLaunch);
    const switchDebugMessageData = useAppSelector((state) => state.bingoState.switchDebugMessageData);
    const allDebugMessages = useAppSelector((state) => state.bingoState.allDebugMessages);
    const showModalDebugMessage = useAppSelector((state) => state.modalState.showModalDebugMessage);
    const isLoadingUsersWinnersToExport = useAppSelector((state) => state.userState.isLoadingUsersWinnersToExport);
    const [launchingTime, setLaunchingTime] = useState<number>(0);
    const [dragPosition, setDragPosition] = useState({
        x: 12 * window.innerWidth / 100,
        y: 17 * window.innerHeight / 100
    });
    const [dragPositionDebug, setDragPositionDebug] = useState({
        x: 12 * window.innerWidth / 100,
        y: 17 * window.innerHeight / 100
    });
    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    let forced_break_loop = useRef(false);

    async function handleLaunchBallotSemi() {
        if (singleBingo && gameInfo) {
            dispatch(changeLaunchingBallot(true));
            const dataBallotsPlayed = getListBallotPlayedLocalStorage(`listBallotPlayed_${singleBingo.id}`);
            const listBallotPlay: string[] = listBallotBingoLimit(singleBingo.session.bingo_limit);
            const ballotPlay = getBallotGame(listBallotPlay, dataBallotsPlayed, singleBingo.game_mode, singleBingo.session.bingo_limit);
            if (ballotPlay) {
                const word = getWordGame(ballotPlay, singleBingo.session.bingo_limit);
                const dataLaunch: LaunchBallotModel = {
                    'game_id': singleBingo.id.toString(),
                    'launch_mode': 'manual',
                    'new_ballot': ballotPlay
                }
                dataBallotsPlayed.push(ballotPlay);
                setLocalStorageObj(dataBallotsPlayed, `listBallotPlayed_${singleBingo.id}`);
                const resLaunchBallot = await resolveLaunchBallotData(dataLaunch);
                if (resLaunchBallot === 'finalized') {
                    const resGameInfo = await resolveGameInfoData(singleBingo.id);
                    dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                    dispatch(updateGameInfo(resGameInfo));
                    dispatch(endGameForced(singleBingo.id)).then(() => {
                        dispatch(getGameInfo(singleBingo.id)).then(() => {
                            dispatch(getSingleBingo(singleBingo.id)).then(() => {
                                localStorage.removeItem(`listBallotPlayed_${singleBingo.id}`);
                                dispatch(changeLaunchingBallot(false));
                            });
                        });
                    });
                } else {
                    const resGameInfo = await resolveGameInfoData(singleBingo.id);
                    dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                    dispatch(updateGameInfo(resGameInfo));
                    dispatch(changeLaunchingBallot(false));
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            } else {
                alertError('No hay mas balotas');
            }
        }
    }

    async function handleLaunchBallotAuto() {
        let break_loop = false;
        if (singleBingo && gameInfo) {
            while (true) {
                if (break_loop || forced_break_loop.current) {
                    dispatch(changeIsAutomaticLaunch(false));
                    dispatch(resetLaunchingBallot(false));
                    dispatch(changePlayLaunchBallotAuto(false));
                    break;
                }
                const thrownBallots = getListBallotPlayedLocalStorage(`listBallotPlayed_${singleBingo.id}`);
                const listBallotPlay: string[] = listBallotBingoLimit(singleBingo.session.bingo_limit);
                const ballotPlay = getBallotGame(listBallotPlay, thrownBallots, singleBingo.game_mode, singleBingo.session.bingo_limit);
                if (ballotPlay) {
                    const word = getWordGame(ballotPlay, singleBingo.session.bingo_limit);
                    const dataLaunch: LaunchBallotModel = {
                        'game_id': singleBingo.id.toString(),
                        'launch_mode': 'manual',
                        'new_ballot': ballotPlay
                    }
                    thrownBallots.push(ballotPlay);
                    setLocalStorageObj(thrownBallots, `listBallotPlayed_${singleBingo.id}`);
                    const resLaunchBallotAuto = await resolveLaunchBallotData(dataLaunch);
                    console.log('respuesta de launch balot');
                    console.log(resLaunchBallotAuto);
                    if (resLaunchBallotAuto === 'finalized') {
                        const resGameInfo = await resolveGameInfoData(singleBingo.id);
                        dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                        dispatch(updateGameInfo(resGameInfo));
                        dispatch(endGameForced(singleBingo.id)).then(() => {
                            dispatch(getSingleBingo(singleBingo.id)).then(() => {
                                localStorage.removeItem(`listBallotPlayed_${singleBingo.id}`);
                                dispatch(changeLaunchingBallot(false));
                                dispatch(changeIsAutomaticLaunch(false));
                            });
                        });
                        console.log('break is finalized');
                        break_loop = true;
                    }
                    if (resLaunchBallotAuto) {
                        const resGameInfo = await resolveGameInfoData(singleBingo.id);
                        dispatch(launchBallotNewMessageSocket(socketBingo, dataLaunch));
                        dispatch(updateGameInfo(resGameInfo));
                        dispatch(changeLaunchingBallot(false));
                        await new Promise(resolve => setTimeout(resolve, 4000));
                    }
                } else {
                    alertError('No hay mas balotas');
                    break;
                }
            }
        }
    }

    useEffect(() => {
        if (singleBingo && singleBingo.status !== 'end') {
            const dataBallotsPlayed: string[] | null = getLocalStorageObj(`listBallotPlayed_${singleBingo.id}`);
            if (dataBallotsPlayed === null) {
                updateGameInfoData(singleBingo.id).then((gameInfo) => {
                    if (gameInfo !== false) {
                        setLocalStorageObj(gameInfo.listBallotPlayed, `listBallotPlayed_${singleBingo.id}`);
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        forced_break_loop.current = false;
    }, []);

    function startLaunchBallotAuto() {
        if (launchingTime > 0) {
            dispatch(changePlayLaunchBallotAuto(true));
            handleLaunchBallotAuto().then(() => {
            });
        } else {
            alertInfo('Debes ingresar un valor de tiempo en segundos');
        }
    }

    useEffect(() => {
        if (singleBingo) {
            dispatch(getGameInfo(singleBingo.id)).then(() => {
            });
        }
    }, [dispatch]);

    useEffect(() => {
        console.log('change play launch ballot auto');
        console.log(playLaunchBallotAuto);
    }, [playLaunchBallotAuto]);

    function finishLaunchBallotAuto() {
        dispatch(changePlayLaunchBallotAuto(false));
        forced_break_loop.current = true;
    }

    useEffect(() => {
        console.log('lista de winners en el componente showGame');
        console.log(gameInfo?.showWinners);
        console.log('informacion del juego sigleBingo........');
        console.log(singleBingo);
        console.log('informacion de la sesion singleSessionDataBase........');
        console.log(singleSessionDatabase);
        console.log('informacion de las balotas del juego........');
        console.log(gameInfo);
    }, [gameInfo]);

    function redirectSingleSession() {
        console.log('click de redirect sigle session');
        if (singleSessionDatabase) {
            history.push(`/admin/session/${singleSessionDatabase.id}/games`);
            dispatch(openAndCloseModalEditGame(false));
            dispatch(openUserInfo(false));
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(openAndCloseModalLaunch_Ballot_Manual(''));
            dispatch(cleanSingleFinishedGame());
        }
    }

    function handleStartGame() {
        console.log('click para iniciar juego');
        if (singleBingo && singleSessionDatabase) {
            dispatch(startGameSocket(socketBingo, singleBingo.id)).then(() => {
            });
        }
    }

    function handleOpenModalEditGame() {
        if (singleBingo && singleBingo.status === 'inactive') {
            dispatch(openAndCloseModalEditGame(true));
        } else {
            alertInfo('Este juego ya inicio, no se puede edtiar');
        }
    }

    async function handleDeleteGame() {
        console.log('click eliminar juego');
        if (singleBingo) {
            const confirmDeleteGame = await alertConfirmDelete();
            console.log(confirmDeleteGame);
            if (confirmDeleteGame && singleBingo) {
                dispatch(deleteBingo(singleBingo.id)).then(() => {
                    dispatch(getSingleSessionDatabase(singleBingo.session_id));
                    dispatch(changeSessionSelected(singleBingo.session_id));
                    dispatch(openAndCloseModalEditGame(false));
                    removeItemLocalStorage(`game${singleBingo.id}`);
                    removeItemLocalStorage(`winners${singleBingo.id}`);
                    history.push(`/admin/session/${singleBingo.session_id}`);
                });
            }
        }
    }

    function handleOpenModalAutomaticLaunch() {
        console.log('click para abrir el modal lanzamiento automatico');
        dispatch(changeIsAutomaticLaunch(!isAutomaticLaunch));
    }

    function handleLaunchingTime(value: string) {
        if (/\D/.test(value)) {
            alertError('Solo se acepta números');
            return;
        }
        if (parseInt(value) === 0) {
            alertError('El número debe ser mayor a 0');
            return;
        }
        setLaunchingTime(parseInt(value));
    }

    function handleOpenCardsWinners() {
        if (singleBingo && singleSessionDatabase && gameInfo) {
            console.log('lista de seriales de ganadores--------------------');
            console.log(gameInfo.showWinners);
            const listSerials: string[] = gameInfo.showWinners.map(winner => winner.serial);
            console.log('lista de seriales');
            console.log(listSerials);
            dispatch(getUsersWinnersExport(listSerials, singleSessionDatabase.id.toString(), singleBingo.bingo_name, singleBingo.game_mode)).then(() => {
                dispatch(openAndCloseModalCardsWinnerUserAndGame(true));
            });
        } else {
            alertInfo('El juego aun no ha terminado, debes terminar el juego');
        }
    }

    const handleDrag = (x: number, y: number) => {
        setDragPosition({x, y});
    };

    const handleDragDebug = (x: number, y: number) => {
        setDragPositionDebug({x, y});
    };

    // sockets listen
    useEffect(() => {

        if (singleBingo) {
            // Escuchar la rerspuesta del servidor al iniciar el juego
            dispatch(startGameResponseSocket(socketBingo, singleBingo.id)).then(() => {
            });
        }

        if (singleBingo) {
            // Escuchar la rerspuesta del servidor al lanzar la balota para crear nuevo mensaje
            dispatch(launchBallotNewMessageResponseSocket(socketBingo, singleBingo.id)).then(() => {
            });
        }

        function handleDisconnect() {
            console.log('Se perdió la conexión. Intentando reconectar...')
            socketBingo.connect();
        }

        socketBingo.on('disconnect', handleDisconnect);

        // Retirar el listener cuando el componente se desmonta
        return () => {
            socketBingo.off('start_game_response');
            socketBingo.off('launch_ballot_response');
            socketBingo.off('launch_ballot_new_message_response');
            socketBingo.off('disconnect', handleDisconnect);
        };
    }, []);

    function handlePlayLaunchBallotAuto(data: boolean) {
        dispatch(changePlayLaunchBallotAuto(data));
    }

    // useEffect(() => {
    //     console.log('cambios del launchingBallot...............');
    //     console.log(launchingBallot);
    //     console.log('lanzamiento de mesajes debug...............');
    //     console.log(switchDebugMessageData);
    //     console.log('este es para abrir el modal lanzamiento automatico');
    //     console.log(isAutomaticLaunch);
    //     console.log('esta el la lista state');
    //     console.log(gameInfo);
    // }, [launchingBallot, switchDebugMessageData, isAutomaticLaunch, gameInfo]);

    function handleCloseModalDebug() {
        dispatch(openAndCloseModalDebugMessage(false));
        dispatch(clearDebugMessage());
    }

    function handleReloadDebugMessage() {
        dispatch(switchDebugMessage(true));
    }

    useEffect(() => {
        if (singleSessionDatabase) {
            dispatch(getCountCardsByBingoLimit(singleSessionDatabase.bingo_limit)).then(() => {
            });
        }
    }, []);

    return (
        <ShowGameStyled
            background_game={themeActive.theme ? themeActive.theme.background_game : ''}
            background_winners_game={themeActive.theme ? themeActive.theme.background_winners_game : ''}
            background_numbers_game={themeActive.theme ? themeActive.theme.background_numbers_game : ''}
        >
            <div className="container">
                <Accumulated
                    type="showGame"
                    clickDelete={handleDeleteGame}
                    clickEdit={handleOpenModalEditGame}
                    click={redirectSingleSession}
                    width={84}
                    height={7}
                    margin={0}
                    top={4}
                    left={33}
                />

                <SessionInfoBingo
                    bingoName={singleBingo?.bingo_name}
                    bingoPrize={singleBingo?.prize}
                    gameMode={singleBingo?.game_mode}
                    image={singleBingo?.main_image}
                />

                <div className="container-sup-numbers">
                    <div className="container-int-numbers">
                        <Numbers
                            listBallotState={gameInfo}
                        />
                    </div>
                </div>

                <div className="container-last-ballots">
                    <div className="container-sup-winner">
                        <div className="container-inf-winner">
                            <div className="header-card-winner">
                                <span className="players">Jugadores</span>
                                <span className="cards">Tarjetones</span>
                                <span className="winners">Ganadores</span>
                            </div>
                            <div className="body-card-winner">
                                <div className="card-winners">
                                    {gameInfo && gameInfo.showWinners.map((winner: any, index: any) => (
                                        <div key={index} className="content-winner">
                                            <span className="name-winner">{winner.name}</span>
                                            <div className="container-serial">
                                                {winner.serial}
                                            </div>
                                            <img
                                                className="start-winner"
                                                alt="img-start-winner"
                                                src="/img/newImage/img-start-winner.svg"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button
                                disabled={isLoadingAllCardsWinnerUserAndGame}
                                onClick={handleOpenCardsWinners} type="button"
                            >
                                {isLoadingUsersWinnersToExport ? 'Cargando...' : 'Ver Ganadores'}
                            </button>
                        </div>
                    </div>
                    <div className="container-play-actions">
                        {singleBingo && singleBingo.status === 'inactive' && !startingGame &&
                            <div className="container-start-game">
                                <img
                                    onClick={handleStartGame}
                                    className="img-play-launch-ballot"
                                    alt="star-game-img"
                                    src="/img/new-img-change/Juego en Proceso.png"
                                />
                                {/*<img*/}
                                {/*    className="img-text-start-game"*/}
                                {/*    alt="star-game-img"*/}
                                {/*    src="/img/newImage/EmpezarJuego.png"*/}
                                {/*/>*/}
                            </div>

                        }
                        {singleBingo && singleBingo.status === 'inactive' && startingGame &&
                            <div className="container-starting-game">
                                <img
                                    className="img-play-launch-ballot-spin"
                                    alt="loanding-lanch-ballot"
                                    src="/img/newImage/img-loanding-launch-ballot.svg"
                                />
                                <img
                                    className="img-text-starting-game"
                                    alt="loanding-lanch-ballot"
                                    src="/img/newImage/IniciandoJuego.png"
                                />
                            </div>

                        }
                        {singleBingo && singleBingo.status === 'active' && !launchingBallot && !isAutomaticLaunch &&
                            <div className="container-throwing-ballot">
                                <img
                                    onClick={handleLaunchBallotSemi}
                                    className="img-play-launch-ballot"
                                    alt="lanch-ballot"
                                    // src="/img/newImage/img-launch-ballot.svg"
                                    src={themeActive.theme ? themeActive.theme.icon_trow_ballot : "/img/newImage/img-launch-ballot.svg"}
                                />
                                <span onClick={handleOpenModalAutomaticLaunch} className="text-launch-auto">
                                    {isAutomaticLaunch ? 'Cerrar lanzamiento automático' : 'Lanzamiento automático'}
                                </span>
                            </div>
                        }
                        {singleBingo && singleBingo.status === 'active' && !launchingBallot && isAutomaticLaunch &&
                            <div className="container-throwing-ballot">
                                <img
                                    className="img-play-launch-ballot-spin"
                                    alt="loanding-lanch-ballot"
                                    src="/img/newImage/img-loanding-launch-ballot.svg"
                                />
                                {!playLaunchBallotAuto &&
                                    <span onClick={handleOpenModalAutomaticLaunch} className="text-launch-auto">
                                        {isAutomaticLaunch ? 'Cerrar lanzamiento automático' : 'Lanzamiento automático'}
                                    </span>
                                }
                                {playLaunchBallotAuto &&
                                    <img
                                        className="img-text-throwing-ballot"
                                        alt="loanding-lanch-ballot"
                                        src="/img/newImage/LanzandoBalota.png"
                                    />
                                }
                            </div>
                        }
                        {singleBingo && singleBingo.status === 'active' && launchingBallot &&
                            <div className="container-throwing-ballot">
                                <img
                                    className="img-play-launch-ballot-spin"
                                    alt="loanding-lanch-ballot"
                                    src="/img/newImage/img-loanding-launch-ballot.svg"
                                />
                                <img
                                    className="img-text-throwing-ballot"
                                    alt="loanding-lanch-ballot"
                                    src="/img/newImage/LanzandoBalota.png"
                                />
                            </div>
                        }
                        {singleBingo && singleBingo.status === 'end' &&
                            <img
                                className="img-play-launch-ballot-end"
                                alt="end-game-img"
                                src="/img/new-img-change/Juego Terminado.png"
                            />
                        }
                    </div>
                    <LastBallots/>
                    <ButtonReloadGame/>
                </div>
            </div>

            {showModalEditGame &&
                <div className="modal-user-info">
                    <EditGame/>
                </div>
            }
            {isAutomaticLaunch &&
                <DndProvider backend={HTML5Backend}>
                    <DraggableDiv position={dragPosition} onDrag={handleDrag}>
                        <div className="modal-automatic-launch">
                            <label className="title">tiempo de lanzamiento en segundos</label>
                            <input onChange={(event) => handleLaunchingTime(event.target.value)} type="text"/>
                            {!playLaunchBallotAuto &&
                                <button type="button" onClick={startLaunchBallotAuto}>Lanzar balota</button>
                            }
                            {playLaunchBallotAuto &&
                                <button onClick={finishLaunchBallotAuto} type="button">Detener</button>
                            }
                        </div>
                    </DraggableDiv>
                </DndProvider>
            }
            {showModalDebugMessage &&
                <DndProvider backend={HTML5Backend}>
                    <DraggableDiv position={dragPositionDebug} onDrag={handleDragDebug}>
                        <div className="modal-debug-message">
                            <div className="close-modal-automatic-launch">
                                <IoMdCloseCircle className="close-icon" onClick={handleCloseModalDebug}/>
                            </div>
                            <label>Debug</label>
                            <div className="container-debug-message">
                                {allDebugMessages.map((message, index) => (
                                    <span key={index}>{message}</span>
                                )).reverse()}
                            </div>
                            <button
                                onClick={handleReloadDebugMessage}
                                type="button"
                                disabled={switchDebugMessageData}
                            >
                                {!switchDebugMessageData ? 'Debug' : 'Debuging...'}

                            </button>
                        </div>
                    </DraggableDiv>
                </DndProvider>
            }
            {showModalCardsWinnerUserAndGame &&
                <div className="modal-cards-winner-user-and-game">
                    <CardsWinnerUserAndGame/>
                </div>
            }
        </ShowGameStyled>
    );
}
